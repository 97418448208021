import React, { useState } from "react";
import { Container, Box, Paper, Grid, Typography, IconButton, useTheme } from '@mui/material';
import Slider from "react-slick";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Advisor from "./advisor";
import { faArrowLeft, faArrowRightToCity, faChevronLeft, faChevronRight, faCircle as solidCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import img1 from '../../assets/images/advisors/1.png';
import carmen_rondon from '../../assets/images/advisors/rosa4.png';
import portada from '../../assets/images/portada.jpeg'
import geraldin_belloso from '../../assets/images/advisors/geral.webp';
import la_nueva from '../../assets/images/advisors/lanueva.webp';
import { WhatsApp } from "@mui/icons-material";


export default function AboutUs(props)
{
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const advisors = [
        {
            name:'Rosa Rondón',
            title:'Asesora Inmobiliaria',
            /*description:`
                Ocho años trabajando en el area de Bienes Raíces. 
                Certificada por la Cámara Inmobiliaria del estado Anzoátegui, desde el 2018. 
                Profesional, Responsable, Honesta y Empatica, con un Elevado nivel de Pasión por 
                hacer las cosas cada día mejor y con la mejor actitud.`,*/
            description:`8 años trabajando en el area de Bienes Raíces`,
            description2:` 
                Certificada por la Cámara Inmobiliaria del estado Anzoátegui, desde el 2018.`,
            description3:` 
                Soy Profesional, Responsable, Honesta y Empatica, con un Elevado nivel de Pasión por 
                hacer las cosas cada día mejor y con la mejor actitud.`,
            src: carmen_rondon,
            instagram:'https://www.instagram.com/tuasesora.ve?igsh=eWVxdnV2c3ZodmU0',
            whatsApp:'https://api.whatsapp.com/send?phone=584248315942'
        }
    ];

    const handleCarousel = (operator) => {
        if(operator === '+')
        {
            if(value === advisors.length - 1)
                setValue(0)
            else
                setValue(value+1)
        }
        else
        {
            if(value === 0)
                setValue(advisors.length-1)
            else
                setValue(value-1)
        }
    }

    return(
        <Container maxWidth='xl' sx={{pt:5,pb:5}} id='nosotras'>

            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h2" fontWeight={'bold'} textAlign={'center'} color={'primary.main'} mt={5} mb={5}>Sobre mí </Typography>
                </Grid>

                
                {
                    advisors.map((item,index)=>
                        value === index &&
                        <Advisor
                            name={item.name}
                            title={item.title}
                            description={item.description}
                            description2={item.description2}
                            description3={item.description3}
                            src={item.src}
                            instagram={item.instagram}
                            whatsapp={item.whatsApp}
                        />
                    )
                }
            </Grid>
        </Container>
    )
}