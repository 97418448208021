import { Container, Box, Grid, Paper, Typography, Button, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Advisor(props)
{
    return(
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container spacing={3} alignItems={'center'}>
                
                <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                    {/**Name */}
                    <Typography color={'primary.main'} textAlign={'center'} fontWeight={'bold'} variant="h4" mt={2} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                        {props.name}
                    </Typography>

                    <Typography color={'primary.main'} textAlign={'center'} fontWeight={'bold'} variant="h4" mt={2} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                        {props.name}
                    </Typography>

                    {/**Title */}
                    <Typography color={'secondary.main'} textAlign={'center'} fontWeight={'bold'} variant="body1" mt={0} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                        {props.title}
                    </Typography>

                    <Typography color={'secondary.main'} textAlign={'center'} fontWeight={'bold'} variant="body1" mt={0} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                        {props.title}
                    </Typography>


                    {/**Description */}

                    <Typography variant="h6" textAlign={'center'} color={'primary.contrastAux'} mt={3} gutterBottom>
                        {props.description}
                    </Typography>

                    <Typography variant="h6" textAlign={'center'} color={'primary.contrastAux'} mt={3} gutterBottom>
                        {props.description2}
                    </Typography>

                    <Typography variant="h6" textAlign={'center'} color={'primary.contrastAux'} mt={3} gutterBottom>
                        {props.description3}
                    </Typography>

                    <Box sx={{display:'flex',flexDirection:'row', justifyContent:'center',mt:3}}>
                        <IconButton target='_blank' href={props.instagram}>
                            <FontAwesomeIcon size="2x" icon={faInstagram}/>
                        </IconButton>
                        <IconButton target='_blank' href={props.whatsapp}>
                            <FontAwesomeIcon size="2x" icon={faWhatsapp}/>
                        </IconButton>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                    <Box sx={{width:'100%'}}>
                        <img style={{width:'100%',borderRadius:20}} src={props.src} alt="tu asesora" />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}

/**
 * 
 * 
¡ Para ayudarte en tus decisiones inmobiliarias! 

Asesoría personalizada, Atención exclusiva y un servicio integral en Venezuela. 

Contacto | Comprar | Vender | alquilar | invertir | Administración de propiedades
 */