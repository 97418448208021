import { Box, Button, Container, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

import sharkboy from '../../assets/images/sharkboy.png'
import rosa from '../../assets/images/logos/rosa.png';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedin, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";



export default function Footer()
{
    const theme = useTheme();
    const handleGoTo = (e,to) => {
        e.preventDefault();
         window.scrollTo({
             top: document.querySelector("#"+to).offsetTop,
             behavior: "smooth",
         })
     }
    return(
        <Box
            sx={{
                backgroundColor:'primary.main',
                minHeight:{xs:'75vh',sm:'95vh',md:'85vh',lg:'75vh',xl:'75vh'},
                pt:10
            }}
        >
            <Container maxWidth='xl'>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{mb:5}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                                <img
                                    src={rosa}
                                    alt='Rosa Rondon'
                                    style={{width:'55%'}}
                                />
                                
                                <br/><br/><br/>
                                <Button
                                    variant="contained"
                                    color='secondary'
                                    size="large"
                                    onClick={(e)=> handleGoTo(e,'contactanos') }
                                >
                                    <Typography variant='h6'>Escríbeme</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant='h5' component='h2' color='primary.contrastText'>
                                    ¡Comunícate conmigo!
                                </Typography>
                                <Divider sx={{height:3,bgcolor:'secondary.main',width:'45%',mt:0,mb:3}}/>
                                <Typography color='primary.contrastText' variant='h6' component='p' style={{display:'flex',alignItems:'center',marginBottom:20}}>
                                    <WhatsAppIcon fontSize="large" /> +58 424-8315942
                                </Typography>
                                <Typography color='primary.contrastText' variant='h6' component='p' style={{display:'flex',alignItems:'center',marginBottom:20}}>
                                    <LocationOnIcon fontSize="large" /> Lechería, Venezuela
                                </Typography>

                                <Typography color='primary.contrastText' variant='h6' component='p' sx={{display:{xs:'none',sm:'none',md:'flex',lg:'flex',xl:'flex'},alignItems:'center'}}>
                                    <EmailIcon fontSize="large" />&nbsp;rosarondon@tuasesorave.com
                                </Typography>
                                <Typography color='primary.contrastText' variant='h6' component='p' sx={{display:{xs:'flex',sm:'none',md:'none',lg:'none',xl:'none'},alignItems:'center',fontSize:18}}>
                                    <EmailIcon fontSize="large" />&nbsp;rosarondon@tuasesorave.com
                                </Typography>
                                
                                
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                        <Typography variant='h5' component='h2' color='primary.contrastText'>
                            ¡Acompáñame!
                        </Typography>
                        <Divider sx={{height:3,bgcolor:'secondary.main',width:'55%',mt:0,mb:3}}/>
                        <Box sx={{display:'flex',flexDirection:'row', mt:3}}>
                            <IconButton target="_blank" href='https://api.whatsapp.com/send?phone=584248315942'>
                                <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faWhatsapp}/>
                            </IconButton>
                            <IconButton target="_blank" href="https://www.instagram.com/tuasesora.ve?igsh=eWVxdnV2c3ZodmU0">
                                <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faInstagram}/>
                            </IconButton>

                            <IconButton target="_blank" href="https://www.facebook.com/profile.php?id=100054594992888">
                                <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faFacebookF}/>
                            </IconButton>

                            <IconButton target="_blank" href="https://www.tiktok.com/@tuasesora.ve?_t=8nCGs6VnEm6&_r=1">
                                <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faTiktok}/>
                            </IconButton>

                            <IconButton target="_blank" href="https://www.linkedin.com/in/rosa-rond%C3%B3n-891274174?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                                <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faLinkedin}/>
                            </IconButton>
                            
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={5}>
                        <Typography variant="h6" component='p' color='primary.contrastText'>
                            © {moment().format('YYYY')} Tu Asesora. Todos los derechos reservados.
                        </Typography>
                        <Button variant="text" sx={{textTransform:'none'}} href="https://technologicalsharks.com/" target="_blank">
                            <Typography variant="body2" component='p' color='primary.contrastText'>
                                Powered By Technological Sharks
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}