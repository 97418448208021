import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActionArea, CardContent, Container, Divider, Grid, Paper, Tab, Tabs, Typography, useTheme, IconButton } from "@mui/material";
import PropTypes from 'prop-types';
import ImageButtom from "../../components/imageButton";

/**imagenes */
import comprar from '../../assets/images/services/comprar.png';
import vender from '../../assets/images/services/vender.png';
import alquilar from '../../assets/images/services/alquiler.png';
import invertir from '../../assets/images/services/invertir.png';
import administracion from '../../assets/images/services/administracion.png';
import gestion from '../../assets/images/services/gestion.png';



import desarrolloPersonalizado from '../../assets/images/services/desarrollo-soft-tls.png';
import soporte_tls from '../../assets/images/services/soporte-tls.png';
import redes_tls from '../../assets/images/services/redes-tls.png';
import blockchain_tls from '../../assets/images/services/blockchain-tls.png';
import consulting_tls from '../../assets/images/services/consulting-it-tls.png';
import bi_tls from '../../assets/images/services/bi-tls.png';
import software_tls from '../../assets/images/services/software-tls.png';
import web_tls from '../../assets/images/services/web-tls.png';
import mobile_tls from '../../assets/images/services/mobile-tls.png';


import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuysellads } from "@fortawesome/free-brands-svg-icons";
import Service from "./Service";
import { faArrowLeft, faArrowRightToCity, faChevronLeft, faChevronRight, faCircle as solidCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";



export default function Services()
{
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const services = [
        {
            title:'Comprar',
            description:'Busco tu inmueble ideal en función de tus deseos y presupuesto; guiándote en cada paso del proceso.',
            slogan:'¡Encontremos tu inmueble deseado!'
        },
        {
            title:'Vender',
            description:'Destaco tu inmueble para atraer al comprador adecuado con evaluación precisa y estrategias de mercado.',
            slogan:'¡Hagamos que la venta de tu inmueble sea un éxito!'
        },
        {
            title:'Alquilar',
            description:'Te ayudo a encontrar la mejor opción de alquiler; esa que cumpla con tu estilo de vida, criterios y presupuesto.',
            slogan:'¡Comencemos a buscar tu próximo inmueble!'
        },
        {
            title:'Invertir',
            description:'Te conecto con los inmuebles correctos para tus inversiones en Bienes Raíces.',
            slogan:'¡Potencia tu inversión conmigo!'
        },
        {
            title:'ADMINISTRACIÓN DE PROPIEDADES',
            description:'Me encargo de cuidar tu inmueble, pagar los servicios, hacer reparaciones, mantenerlo limpio y seguro.',
            slogan:'¡Tu inmueble en buenas manos!'
        },
        {
            title:'GESTIÓN DE TRAMITES',
            description:'Te ofrezco asesoría y representación ante diversos organismos públicos y privados. Elaboración de documentos (poderes, compra-venta de inmuebles, acta constitutiva)',
            slogan:'¡Agilicemos tus tramites sin perder tiempo!'
        }
    ];

    const handleCarousel = (operator) => {
        if(operator === '+')
        {
            if(value === services.length - 1)
                setValue(0)
            else
                setValue(value+1)
        }
        else
        {
            if(value === 0)
                setValue(services.length-1)
            else
                setValue(value-1)
        }
    }

    return(
        <Container maxWidth='xl' sx={{pt:5,pb:5}} id='servicios'>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item xs={12}>
                    <Typography variant='h2' fontWeight={'bold'} textAlign={'center'}>
                        Servicios
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant="h4" component='h2' sx={{fontWeight:'bold',color:'primary.main'}}>
                        Estoy aquí para ayudarte en tus decisiones inmobiliarias
                        <Divider sx={{height:3,bgcolor:'secondary.main',width:'14%',mb:3}}/>
                    </Typography>
                </Grid>
            
                <Grid item xs={12} sm={12}md={12} lg={12} xl={12}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <ImageButtom
                            src={comprar}
                            alt='comprar'
                            title='COMPRAR'
                            onClick={()=>setValue(0)}
                            border={value === 0 ? 'solid #00d0c6' : null}
                        />
                        <ImageButtom
                            src={vender}
                            alt='vender'
                            title='VENDER'
                            onClick={()=>setValue(1)}
                            border={value === 1 ? 'solid #00d0c6' : null}
                        />
                        <ImageButtom
                            src={alquilar}
                            alt='alquilar'
                            title='ALQUILAR'
                            onClick={()=>setValue(2)}
                            border={value === 2 ? 'solid #00d0c6' : null}
                        />

                        <ImageButtom
                            src={invertir}
                            alt='invertir'
                            title='INVERTIR'
                            onClick={()=>setValue(3)}
                            border={value === 3 ? 'solid #00d0c6' : null}
                        />

                        <ImageButtom
                            src={administracion}
                            alt='administracion'
                            title='ADMINISTRACIÓN'
                            onClick={()=>setValue(4)}
                            border={value === 4 ? 'solid #00d0c6' : null}
                        />

                        <ImageButtom
                            src={gestion}
                            alt='gestión'
                            title='GESTIÓN'
                            onClick={()=>setValue(5)}
                            border={value === 5 ? 'solid #00d0c6' : null}
                        />
                    </Grid>
                </Grid>

                <Grid item lg={3} xl={3} sx={{display:{xs:'none',sm:'none',md:'flex',lg:'flex',xl:'flex'}}} alignItems={'center'} justifyContent={'center'}>
                    <IconButton onClick={()=>handleCarousel('-')}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </IconButton>
                </Grid>

                {
                    services.map((item,index)=>
                        value === index &&
                        <Service
                            title={item.title}
                            description={item.description}
                            slogan={item.slogan}
                        />
                    )
                }

                <Grid item lg={3} xl={3} sx={{display:{xs:'none',sm:'none',md:'flex',lg:'flex',xl:'flex'}}} alignItems={'center'} justifyContent={'center'}>
                    <IconButton onClick={()=>handleCarousel('+')}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </IconButton>
                </Grid>

                <Grid item lg={12} xl={12} display={'flex'} justifyContent={'center'} mt={5}>
                    <Grid container spacing={3} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Grid 
                            item 
                            xs={1} sm={1} md={1} lg={1} xl={1}
                            sx={{display:{xs:'flex',sm:'flex',md:'none',lg:'none',xl:'none'},mb:2.5}}
                            alignItems={'center'} justifyContent={'center'}
                        >
                            <IconButton onClick={()=>handleCarousel('-')}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </IconButton>
                        </Grid>
                        
                        <Grid xs={10} sm={10} md={10} lg={10} xl={10} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
                            {
                                services.map((item,index)=>
                                    <IconButton onClick={()=>setValue(index)}>
                                    {
                                        value === index
                                        ? <FontAwesomeIcon style={{margin:2}} color={theme.palette.primary.main} icon={solidCircle}/>
                                        : <FontAwesomeIcon style={{margin:2}} color={theme.palette.primary.main} icon={faCircle}/>
                                    }
                                    </IconButton>
                                )
                            }
                        </Grid>
                        
                        <Grid
                            item
                            xs={1} sm={1} md={1} lg={1} xl={1}
                            sx={{display:{xs:'flex',sm:'flex',md:'none',lg:'none',xl:'none'},mb:2.5}}
                            alignItems={'center'} justifyContent={'center'}
                        >
                            <IconButton onClick={()=>handleCarousel('+')}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </Grid>
        </Container>
    )
}