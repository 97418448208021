import React, { useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import WebSiteNavigation from "./navigation/WebSiteNavigation";
import { createTheme } from "@mui/material";
import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/darkTheme";




export default function App()
{
  const [mode, setMode] = React.useState('light');
  const theme = React.useMemo(
    () => 
    createTheme(
        {
            typography:
            {
                fontFamily:['Montserrat','sans-serif',].join(','),
            }
        },
        mode === 'light' ? lightTheme : darkTheme,
    )
)
    return(
        <Router>
            <WebSiteNavigation/>
        </Router>
    )
}

/*import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/