import React from 'react';
import { Box, Button, Container, Divider, Grid, Link, Typography, useTheme } from '@mui/material';

import { motion } from "framer-motion"
import Services from './services';

import portada from '../assets/images/portada.jpeg'
import portada2 from '../assets/images/portada1.png'
import CIVO_LOGO from '../assets/images/allies/CIVO_LOGO.jpg'
import Portafolio from './portafolio';
import Technologies from './services/technologies';
import AboutUs from './aboutUs/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faMapMarkerAlt, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import ContactUs from './contactUs';

import software_tls from '../assets/images/services/software-tls.png';

import invertir_img from '../assets/images/invertir.png'
import Service from '../components/Service';

export default function Home()
{
    const theme = useTheme()
    return(
        <Box sx={{overflowX:'hidden'}}>
            {/**Eslogan  Inicio */}
            <Box 
                sx={{
                    position:'relative', overflowX:'hidden',
                    height:'100vh',
                    display:'flex',
                    alignItems:'center'
                }}
            >
                <Box
                    sx={{
                        height:'100vh',width:'100%',
                        display:'flex',
                        //justifyContent:'center',
                        alignItems:'center',
                        backgroundImage: "url(" + portada2 + ")",
                        backgroundPosition:'start',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'cover',
                        position:'absolute',
                        opacity:0.4,zIndex:-1
                    }}
                ></Box>

                <Container  maxWidth='lg' sx={{mt:5}}>
                    <Grid container>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.7 }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant='h2' component='h2' sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                                    Tus decisiones inmobiliarias
                                    <br/>
                                    con <span style={{color:theme.palette.primary.main,fontWeight:'bold'}}>la mejor asesoría</span>
                                </Typography>
                                <Typography variant='h4' component='h2' sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                                    Tus decisiones inmobiliarias
                                    <br/>
                                    con <span style={{color:theme.palette.primary.main,fontWeight:'bold'}}>la mejor asesoría</span>
                                </Typography>
                            </Grid>
                        </motion.div>
                    </Grid>
                    <Divider sx={{height:3,bgcolor:'primary.main',width:'16%',mt:3,mb:3}}/>
                    <Typography variant='h5' component='h5' sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'}}}>
                        Haré que tu experiencia sea <br/>
                        fácil, rápida y segura
                    </Typography>
                    <Typography variant='h6' component='h6' sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'}}}>
                        Haré que tu experiencia sea <br/>
                        fácil, rápida y segura
                    </Typography>

                    <Button
                        variant='contained'
                        sx={{mt:5}}
                        component={'a'}
                        //href={"tel: +58 424-8315942"}
                        target='_blank'
                        href='https://api.whatsapp.com/send?phone=584248315942'
                    >
                        <Typography variant='h6'>Escríbeme</Typography>
                    </Button>
                    {/*<Grid container sx={{mt:5}} display={'flex'} flexDirection={'column'}>
                        <Typography><FontAwesomeIcon color={theme.palette.primary.main} icon={faMapMarkerAlt} /> &nbsp;&nbsp;Lechería, Anzoátegui. Venezuela</Typography>
                        <Grid item lg={2}>
                        <Link
                            sx={{display:'flex',alignItems:'center',textDecoration:'none',color:'#000',mt:1}}
                            component={'a'}
                            href={"tel: +58 424-8315942"}
                        >
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faPhone} />&nbsp;
                            +58 424-8315942
                        </Link>
                        </Grid>
                        
                        <Grid item lg={2}>
                            <Typography
                                sx={{display:'flex',alignItems:'center',textDecoration:'none',color:'#000',mt:1}}
                                component={'a'}
                                target='_blank'
                                href='https://www.instagram.com/tuasesora.ve/'
                            >
                                <FontAwesomeIcon color={theme.palette.primary.main} icon={faInstagram} /> &nbsp; @tuasesora.ve
                            </Typography>
                        </Grid>
                </Grid>*/}
                </Container>
            </Box>

            {/**Servicios */}
            <Services/>

            
            {/**Nosotras */}
            <AboutUs/>

            {/**Servicios 
            <Services/>*/}

            {/**Contactanos */}
            <ContactUs/>
            
            {/**Portafolio 
            <Portafolio/>*/}
            
        </Box>
    )
}