import {Grid, Typography, useTheme} from '@mui/material'

export default function Service(props)
{
    const theme = useTheme();
    return(
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={5}>
            <Typography variant="h5" textAlign={'center'} sx={{fontWeight:'bold',textTransform:'uppercase'}}>{props.title}</Typography>    
            <Typography variant='h6' textAlign={'center'} component='p' color='primary.contrastAux' mt={3}>
                {props.description}
                <br/><br/>
                <span style={{color:theme.palette.secondary.main,fontWeight:'bold'}}>
                    {props.slogan}
                </span>
            </Typography>
        </Grid>
    )
}