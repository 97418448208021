import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Menu, Typography, Container, Button, Drawer, ListItem, List, Divider, Link, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useHistory } from 'react-router-dom';

import ThemeMode from '../themes/themeMode';

import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../assets/images/logos/logo.png'
import ScrollToColor01 from './ScrollToColor';

const pages = [
    {name:'Inicio',route:'wallet'},
    {name:'Nosotros',route:'empresas'},
    {name:'Tienda',route:'https://www.anzteenamora.shop/'}
];


const ResponsiveAppBar = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const history = useHistory()
        const redirect = (route) => {
        history.push(route)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setDrawerIsOpen(open);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleGoTo = (e,to) => {
       e.preventDefault();
        window.scrollTo({
            top: document.querySelector("#"+to).offsetTop,
            behavior: "smooth",
        })
    }
 

    return (
        <ScrollToColor01>
            <AppBar position="fixed" color="transparent" elevation={1}>
                <Container maxWidth="xl">
                    <Toolbar component='div' disableGutters sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Box>
                        <Typography variant='h4' sx={{display:{xs:'none',md:'flex'},'&:hover':{cursor:'pointer'}}} onClick={()=>history.push('/')}>
                            <img
                                src={logo}
                                alt='Tu Asesora'
                                style={{width:'20%'}}
                            />
                        </Typography>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{display:{xs:'flex',md:'none'},'&:hover':{cursor:'pointer'}}}
                            onClick={()=>history.push('/')}
                        >
                            <img
                                src={logo}
                                alt='Tu Asesora'
                                style={{width:'40%'}}
                            />
                        </Typography>
                        </Box>
                        <Box sx={{ color:'#FFF', flexGrow: 0, display:{xs:'none',md:'flex'},alignItems:'center' }}>
                            
                            
                            <Button
                                onClick={()=> redirect('/') }
                                sx={{  display: 'block' }}
                                variant='text'
                            >
                                <Typography color={'primary.contrastText'}>
                                    Inicio
                                </Typography>
                            </Button>
                            
                            <Button
                                onClick={(e)=> handleGoTo(e,'servicios') }
                                sx={{ display: 'block' }}
                                variant='text'
                            >
                                <Typography color={'primary.contrastText'}>
                                    Servicios
                                </Typography>
                            </Button>

                            <Button
                                onClick={(e)=> handleGoTo(e,'nosotras') }
                                sx={{ display: 'block' }}
                                variant='text'
                            >
                                <Typography color={'primary.contrastText'} sx={{width:80}}>
                                    Sobre Mí
                                </Typography>
                            </Button>

                            <Button
                                onClick={(e)=> handleGoTo(e,'contactanos') }
                                sx={{  display: 'block' }}
                                variant='text'
                            >
                                <Typography color={'primary.contrastText'}>
                                    Escríbeme
                                </Typography>
                            </Button>
                            
                            
                            {/*<MyApp mode={props.mode}/>*/}
                        </Box>

                        
                    </Toolbar>
                </Container>
            </AppBar>
        </ScrollToColor01>
    );
};
export default ResponsiveAppBar;
