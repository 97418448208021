import WebsiteRoutes from "../apiRoutes/websiteRoutes";
import { POST } from "./ConsumeApiController";

//send email from contact us form
export async function SendEmailFromContactUsForm(nombre,apellido,email,telefono,servicio,ciudad,mensaje)
{
    const params = [
        {name:'nombre',value:nombre},
        {name:'apellido',value:apellido},
        {name:'email',value:email},
        {name:'telefono',value:telefono},
        {name:'servicio',value:servicio},
        {name:'ciudad',value:ciudad},
        {name:'mensaje',value:mensaje}
    ];

    const response = await POST(WebsiteRoutes.Faqs.ContactUsForm,params);
    return response;
}