import { useEffect, useState } from "react"
import { Box, Button, Container, Grid, IconButton, MenuItem, Paper, TextField, Typography, useTheme } from "@mui/material"
import MapContainer from '../../components/map2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookF, faInstagram, faLinkedin, faTiktok, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import contactUsImg from '../../assets/images/contacUS2.webp'
import img1 from '../../assets/images/1.jpeg';
import img2 from '../../assets/images/2.jpeg';
import { SendEmailFromContactUsForm } from "../../controllers/ContactUsContrtoller";

export default function ContactUs()
{
    const theme = useTheme();

    const [nombre,setNombre] = useState('');
    const [apellido,setApellido] = useState('');
    const [telefono,setTelefono] = useState('');
    const [email,setEmail] = useState('');
    const [servicio,setServicio] = useState('');
    const [ciudad,setCiudad] = useState('');
    const [mensaje,setMensaje] = useState('');

    const [errorNombre,setErrorNombre] = useState(false);
    const [errorApellido,setErrorApellido] = useState(false);
    const [errorEmail,setErrorEmail] = useState(false);
    const [errorTelefono,setErrorTelefono] = useState(false);
    const [errorServicio,setErrorServicio] = useState(false);
    const [errorCiudad,setErrorCiudad] = useState(false);
    const [errorMensaje,setErrorMensaje] = useState(false);


    const [isLoadingData,setIsLoadingData] = useState(false);
    const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');


    const formVerification = () => {
        var ban = 0;
        
        if(nombre === '')
        {
            setErrorNombre(true);
            ban = 1;
        }

        if(apellido === '')
        {
            setErrorApellido(true);
            ban = 1;
        }

        if(email === '')
        {
            setErrorEmail(true);
            ban = 1;
        }

        if(telefono === '')
        {
            setErrorTelefono(true);
            ban = 1;
        }

        if(servicio === '')
        {
            setErrorServicio(true);
            ban = 1;
        }

        if(ciudad === '')
        {
            setErrorCiudad(true);
            ban = 1;
        }

        if(ciudad === '')
        {
            setErrorMensaje(true);
            ban = 1;
        }
        
        return ban;
    }

    const handleSubmit = async () => {
        if(formVerification() === 1)
            return;

        setIsLoadingData(true);
        let response = await SendEmailFromContactUsForm(nombre,apellido,email,telefono,servicio,ciudad,mensaje);
        if(response.success === true)
        {
            setTitle('Operación Exitosa');
            setMessage(response.message);
            setActionText('Finalizar');
            setSuccess(true);
            setShow(true);
        }
        setIsLoadingData(false);
    }

    const handleSuccess = () => {

        setNombre('');      setErrorNombre(false);
        setApellido('');    setErrorApellido(false);
        setEmail('');       setErrorEmail(false);
        setTelefono('');    setErrorTelefono(false);
        setServicio('');    setErrorServicio(false);
        setCiudad('');      setErrorCiudad(false);
        setMensaje('');     setErrorMensaje(false);

        setShow(false);

    }

    return(
        <div>
            <Box 
                sx={{
                    position:'relative',
                    height:300,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    bgcolor:'primary.main',
                    zIndex:-1,mb:5
                }}
                id='contactanos'
            >
                <Container>
                    <Typography variant='h2' component='h2' color={'primary.contrastText'} sx={{display:{xs:'none',sm:'none',md:'block',lg:'block',xl:'block'},mb:5}}>
                        Escríbeme
                    </Typography>
                    <Typography variant='h4' component='h2' textAlign={'center'} color={'primary.contrastText'} sx={{display:{xs:'block',sm:'block',md:'none',lg:'none',xl:'none'},mb:5}}>
                        Escríbeme
                    </Typography>
                </Container>
                
            </Box>
        <Container sx={{mt:-10}}>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid item lg={12}>
                    <Paper elevation={4} sx={{padding:0}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Box sx={{pl:4,pb:3,pr:4}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h5" sx={{fontWeight:'bold',color:'primary.main'}}>
                                                Empecemos
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Nombre'
                                                    id="nombre"
                                                    name="nombre"
                                                    value={nombre}
                                                    onChange={(e)=>setNombre(e.target.value)}
                                                    onBlur={ () => (nombre === '' ? setErrorNombre(true) : setErrorNombre(false) ) }
                                                    error = {errorNombre}
                                                    helperText={errorNombre && 'Ingrese su nombre'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Apellido'
                                                    id="apellido"
                                                    name="apellido"
                                                    value={apellido}
                                                    onChange={(e)=>setApellido(e.target.value)}
                                                    onBlur={ () => (apellido === '' ? setErrorApellido(true) : setErrorApellido(false) ) }
                                                    error = {errorApellido}
                                                    helperText={errorApellido && 'Ingrese su apellido'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Correo Electrónico'
                                                    id="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e)=>setEmail(e.target.value)}
                                                    onBlur={ () => (email === '' ? setErrorEmail(true) : setErrorEmail(false) ) }
                                                    error = {errorEmail}
                                                    helperText={errorEmail && 'Ingrese su correo electrónico'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Teléfono'
                                                    id="telefono"
                                                    name="telefono"
                                                    value={telefono}
                                                    onChange={(e)=>setTelefono(e.target.value)}
                                                    onBlur={ () => (telefono === '' ? setErrorTelefono(true) : setErrorTelefono(false) ) }
                                                    error = {errorTelefono}
                                                    helperText={errorTelefono && 'Ingrese su numero de teléfono'}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    id="servicio"
                                                    name="servicio"
                                                    label="Servicio"
                                                    variant="outlined"
                                                    value={servicio}
                                                    onChange={(e)=>setServicio(e.target.value)}
                                                    onBlur={ () => (servicio === '' ? setErrorServicio(true) : setErrorServicio(false) ) }
                                                    error = {errorServicio}
                                                    helperText={errorServicio && 'Seleccione un servicio'}
                                                >
                                                    <MenuItem value='Compra'>Compra</MenuItem>
                                                    <MenuItem value='Venta'>Venta</MenuItem>
                                                    <MenuItem value='Alquiler'>Alquiler</MenuItem>
                                                    <MenuItem value='Inversión'>Inversion</MenuItem>
                                                    <MenuItem value='Administración de Propiedades'>Administración de Propiedades</MenuItem>
                                                    <MenuItem value='Gestión de Tramites'>Gestión de Tramites</MenuItem>
                                                </TextField>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextField
                                                    fullWidth
                                                    label='Ciudad'
                                                    id="ciudad"
                                                    name="ciudad"
                                                    value={ciudad}
                                                    onChange={(e)=>setCiudad(e.target.value)}
                                                    onBlur={ () => (ciudad === '' ? setErrorCiudad(true) : setErrorCiudad(false) ) }
                                                    error = {errorCiudad}
                                                    helperText={errorCiudad && 'Ingrese la ciudad de donde nos escribe'}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline rows={4}
                                                    label='Mensaje'
                                                    id="mensaje"
                                                    name="mensaje"
                                                    value={mensaje}
                                                    onChange={(e)=>setMensaje(e.target.value)}
                                                    onBlur={ () => (mensaje === '' ? setErrorMensaje(true) : setErrorMensaje(false) ) }
                                                    error = {errorMensaje}
                                                    helperText={errorMensaje && 'Ingrese el mensaje'}
                                                />
                                            </Grid>
                                        

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                            <Button
                                                variant="contained"
                                                onClick={()=>handleSubmit()}
                                            >
                                                Enviar mensaje
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{bgcolor:'primary.main',borderBottomRightRadius:10,borderTopRightRadius:10,position:'relative'}}>
                                <Box sx={{pl:4,pb:3,pr:4,color:'primary.contrastText',position:'relative'}}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h5">Datos de Contacto</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography><FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp;&nbsp;Lechería, Anzoátegui. Venezuela</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography><FontAwesomeIcon icon={faPhone} />&nbsp;+58 424-8315942</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography><FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;rosarondon@tuasesorave.com</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} zIndex={50000}>
                                            
                                            <Box sx={{display:'flex',flexDirection:'row'}}>
                                                <IconButton target="_blank" href='https://api.whatsapp.com/send?phone=584248315942'>
                                                    <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faWhatsapp}/>
                                                </IconButton>
                                                <IconButton target="_blank" href="https://www.instagram.com/tuasesora.ve?igsh=eWVxdnV2c3ZodmU0">
                                                    <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faInstagram}/>
                                                </IconButton>

                                                <IconButton target="_blank" href="https://www.facebook.com/profile.php?id=100054594992888">
                                                    <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faFacebookF}/>
                                                </IconButton>

                                                <IconButton target="_blank" href="https://www.tiktok.com/@tuasesora.ve?_t=8nCGs6VnEm6&_r=1">
                                                    <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faTiktok}/>
                                                </IconButton>

                                                <IconButton target="_blank" href="https://www.linkedin.com/in/rosa-rond%C3%B3n-891274174?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                                                    <FontAwesomeIcon color={theme.palette.primary.contrastText} icon={faLinkedin}/>
                                                </IconButton>
                                                
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    
                                </Box>
                                <Box
                                        sx={{
                                            width:'100%',height:'100%',
                                            display:'flex',
                                            //justifyContent:'center',
                                            alignItems:'center',
                                            backgroundImage: "url(" + img1 + ")",
                                            backgroundPosition:'start',
                                            backgroundRepeat:'no-repeat',
                                            backgroundSize:'cover 100%',
                                            opacity:0.2,
                                            position:'absolute',
                                            bottom:0, left:0,
                                            borderTopRightRadius:10,
                                            borderBottomRightRadius:10
                                        }}
                                    ></Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
        <br/><br/><br/><br/>
        </div>
    )
}